<template>
  <a-card :bordered="false">
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
      title="储值卡列表"
      sub-title=""
      @back="() => $router.go(-1)"
    >
    </a-page-header>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="卡号">
              <a-input v-model="queryParam.card_no" placeholder="请输入卡号" allow-clear @keyup.enter.native="$refs.table.refresh(true)"/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <div class="table-operator">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </div>
          </a-col>
          <a-col :md="4" :sm="24">
            <div class="table-operator">
              <a-button type="primary" icon="export" @click="handleExport">导出</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="price_render" slot-scope="text" style="text-align: left; min-width: 90px; max-width: 240px">
        {{ (text / 100).toFixed(2) }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleValueCardRecord(record)">消费记录</a>
        </template>
      </span>
    </s-table>
    <value-card-consume-record-list
      ref="valueCardModal"
      v-if="value_card_visible"
      :visible.sync="value_card_visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleValueCardCancel"
    />
  </a-card>
</template>
<script>

import { valueCardExport, valueCardList } from '@/api/shop_value_card'
import ValueCardConsumeRecordList from '@/views/a-applet/shop_manage/value_card/ValueCardConsumeRecordList'
import { STable } from '@/components'
export default {
  name: 'ValueCardList',
  components: {
    STable,
    ValueCardConsumeRecordList
  },
  data () {
    return {
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '卡号',
          ellipsis: true,
          dataIndex: 'card_no'
        },
        {
          title: '金额',
          ellipsis: true,
          dataIndex: 'value',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '状态',
          ellipsis: true,
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.value_card_status[text] || '无'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading: false,
      queryParam: {},
      mdl: {},
      value_card_visible: false,
      confirmLoading: false,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return valueCardList(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.queryParam.record_id = this.$route.query.id
  },
  methods: {
    handleValueCardCancel () {
      this.value_card_visible = false
    },
    handleValueCardRecord (record) {
      this.mdl = record
      this.value_card_visible = true
    },
    handleExport () {
      valueCardExport(this.queryParam).then((res) => {
        const link = document.createElement('a')
        const blob = new Blob([res], { type: 'application/octet-stream' })
        console.log(blob)
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', `储值卡.xlsx`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }).finally(() => {
        this.confirmLoading = false
      })
    }
  }
}
</script>
<style>
</style>
